'use strict';

import * as _i18n from 'i18n';
import * as _util from 'util';
import jQuery from 'jquery';

window.jQuery = jQuery;

_i18n.loadMessageBundle('./message/message');

import('./css/common.css');
import('./css/dashboard.css');
import('./view/layout').then((Layout) => {
	const layout = new Layout.default();
	_i18n.initLangSelector();
});

