'use strict';
import * as $ from 'jquery';
import 'jquery-ui';
import './css/util.css';
import Modal from 'modal';

const lockScreen = function (keepPrvView) {
	if (typeof keepPrvView === 'undefined') {
		keepPrvView = false;
	}

	if ($('.app-locker').length > 0) {
		console.debug('screen is already locked');
		return;
	}

	const locker = $('<div>').addClass('app-locker');
	locker.append('<div class="lds-css ng-scope"><div class="lds-ellipsis"><div></div><div></div><div></div><div></div></div></div>');
	$('body').append(locker);

	const container = $('.sc-common-view-container');

	if (!keepPrvView) {
		container.addClass('app-locker-fadein-200ms');
		container.css({ opacity: 0 });
	}

	setTimeout(function () {
		//appCtr.css({opacity:0});
		locker.addClass('app-locker-fadein-200ms');
	}, 0);
};

const unlockScreen = function () {
	const locker = $('.app-locker');
	setTimeout(function () {
		locker.addClass('app-locker-fadeout-300ms');
		setTimeout(function () {
			$('.sc-common-view-container').css({ opacity: 1 }).removeClass('app-locker-fadein-200ms');
			locker.remove();
		}, 100);
	}, 100);
};

const setScrolling = function (state) {
	window.app.scrolling = state;
};

const isScrolling = function () {
	return typeof window.app.scrolling === 'undefined' ? false : window.app.scrolling;
};

const addScrollMonitor = function (ele) {
	let scrollTreshold = 30;

	let startTouchData;
	let timer;

	ele.unbind('scroll').bind('scroll', function () {
		setScrolling(true);
		clearTimeout(timer);
		timer = setTimeout(refresh, 150);
	});
	let refresh = function () {
		setScrolling(false);
	};

	// 스크롤 종단에서 더이상 스크롤 되지 않는 상태에서 사용자가 화면을 밀면
	// 무조건 클릭 이벤트가 발생하는 문제 예외 처리 필요
	ele.unbind('touchstart touchmove')
		.bind('touchstart', function () {
			startTouchData = event.touches[0];
		})
		.bind('touchmove', function () {
			let dx = startTouchData.clientX - event.touches[0].clientX;
			let dy = startTouchData.clientY - event.touches[0].clientY;
			let dist = Math.sqrt(dx * dx + dy * dy);

			if (scrollTreshold < dist) {
				setScrolling(true);
				clearTimeout(timer);
				timer = setTimeout(refresh, 150);
			}
		});
};

const preloading = function (imageArray) {
	let n = imageArray.length;
	for (let i = 0; i < n; i++) {
		let img = new Image();
		img.src = imageArray[i];
	}
};

const getClickEventType = function () {
	return window.app.mobile ? 'touchend' : 'click';
};

const isIE = function () {
	return navigator.userAgent.indexOf('MSIE') > 0 || navigator.userAgent.indexOf('Trident') > 0;
};

const downloadBase64Image = function (base64Data, fileName) {
	if (isIE()) {
		window.navigator.msSaveOrOpenBlob(convertBase64ToBlob(base64Data), fileName);
	} else {
		const downloadLink = document.createElement('a');
		downloadLink.href = base64Data;
		downloadLink.download = fileName;
		downloadLink.click();
	}
};

const convertBase64ToBlob = function (base64Image) {
	const parts = base64Image.split(';base64,');
	const imageType = parts[0].split(':')[1];
	const decodedData = window.atob(parts[1]);
	const uInt8Array = new Uint8Array(decodedData.length);
	for (let i = 0; i < decodedData.length; ++i) {
		uInt8Array[i] = decodedData.charCodeAt(i);
	}
	return new Blob([uInt8Array], { type: imageType });
};

const capitalizeFirstLetter = function (string) {
	return string.charAt(0).toUpperCase() + string.slice(1);
};

const addComma = function (num) {
	if (num == null) {
		return num;
	}
	let regexp = /\B(?=(\d{3})+(?!\d))/g;
	return num.toString().replace(regexp, ',');
};

const renderPagination = function (data, state, loadData) {
	const number = data.number + 1;
	const totalPages = data.totalPages;
	const paginationFrag = $('<div>');

	paginationFrag.addClass('ui pagination menu');

	const startNumber = Math.floor(data.number / 10) * 10 + 1;
	const endNumber = (Math.floor(data.number / 10) + 1) * 10;

	if (startNumber > 10) {
		// 11 page over
		const prevSet = $('<a>').addClass('icon item');
		prevSet.append($('<i>').addClass('angle double left icon'));
		prevSet.on('click', function (e) {
			e.preventDefault();
			state.page = startNumber - 2;
			loadData();
			return;
		});

		paginationFrag.append(prevSet);
	}

	if (number > 1) {
		const prev = $('<a>').addClass('icon item');
		prev.append($('<i>').addClass('angle left icon'));
		prev.on('click', function (e) {
			e.preventDefault();
			state.page = number - 2;
			loadData();
			return;
		});
		paginationFrag.append(prev);
	}

	for (let i = startNumber - 1; i < endNumber; i++) {
		if (i === totalPages) {
			break;
		}
		const selected = number === i + 1;
		const pageButton = selected ? $('<strong>') : $('<a>');
		if (selected) {
			pageButton.addClass('active');
		}
		pageButton.addClass('item');
		pageButton.text(i + 1);

		paginationFrag.append(pageButton);
		if (selected) {
			continue;
		}
		pageButton.on('click', function (e) {
			e.preventDefault();
			state.page = i;
			loadData();
			return;
		});
	}

	if (number < totalPages) {
		const next = $('<a>').addClass('item icon');
		next.append($('<i>').addClass('angle right icon'));
		next.on('click', function (e) {
			e.preventDefault();
			state.page = number;
			loadData();
			return;
		});
		paginationFrag.append(next);
	}

	if (endNumber < totalPages) {
		const nextSet = $('<a>').addClass('item icon');
		nextSet.append($('<i>').addClass('angle double right icon'));
		nextSet.on('click', function (e) {
			e.preventDefault();
			state.page = endNumber;
			loadData();
			return;
		});

		paginationFrag.append(nextSet);
	}
	return paginationFrag;
};

const detectOrientation = function () {
	//console.log(!navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape')
	return !navigator.maxTouchPoints ? 'desktop' : !window.screen.orientation.angle ? 'portrait' : 'landscape';
};

const alert = (options) => {
	if (typeof options === 'string') {
		options = {
			message: options,
		};
	}
	const alertModal = new Modal({
		name: options.name ? options.name : 'ALERT',
		width: options.width ? options.width : '480px',
		height: options.height ? options.height : '300px',
		clazz: 'hc-alert-modal',
		afterClose: () => {
			if (typeof options.callback === 'function') {
				options.callback();
			}
		},
	});

	alertModal.open((modal) => {
		const modalBody = modal.find('.hc-modal-body');
		modalBody.focus();
		modalBody.html(options.message);
		const buttonContent = $('<div>').addClass('dialog-button-content');
		const closeButton = $('<button class="ui button">').text('닫기');
		closeButton.on('click', (e) => {
			e.preventDefault();
			alertModal.close();
		});
		buttonContent.append(closeButton);
		modalBody.append(buttonContent);
		closeButton.focus();
	});
};

const confirm = (options) => {
	const confirmModal = new Modal({
		name: options.name ? options.name : 'ALERT',
		width: options.width ? options.width : '480px',
		height: options.height ? options.height : '300px',
		clazz: 'hc-confirm-modal',
	});

	confirmModal.open((modal) => {
		const modalBody = modal.find('.hc-modal-body');
		modalBody.html(options.message);
		const buttonContent = $('<div>').addClass('dialog-button-content');
		const confirmButton = $('<button class="ui teal button">').text('확인');
		confirmButton.on('click', (e) => {
			e.preventDefault();
			if (typeof options.callback === 'function') {
				options.callback(modalBody);
			}
			confirmModal.close();
		});

		const closeButton = $('<button class="ui button">').text('취소');
		closeButton.on('click', (e) => {
			e.preventDefault();
			confirmModal.close();
		});
		buttonContent.append(confirmButton);
		buttonContent.append(closeButton);
		modalBody.append(buttonContent);
		closeButton.focus();
	});
};


export { lockScreen, unlockScreen, setScrolling, isScrolling, addScrollMonitor, preloading, getClickEventType, downloadBase64Image, convertBase64ToBlob, isIE, capitalizeFirstLetter, addComma, renderPagination, detectOrientation, alert, confirm };
