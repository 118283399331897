'use strict';

import * as $ from 'jquery';

const _messageMap = {};
const _messageLoadMap = {};

const getLocale = function () {
	return window.app.locale;
};

const setLocale = function (locale) {
	window.app.locale = locale;
};

const getMessage = function (msgId, forcedLocale) {
	const refLocale = forcedLocale ? forcedLocale : getLocale();
	var msg = _messageMap[refLocale][msgId];
	// console.debug(refLocale, msgId, msg);

	if (typeof msg == 'string' || typeof msg == 'undefined') {
		return msg;
	} else {
		return window.app.mobile ? msg.mobile : msg.desktop;
	}
};

const detectLanguageOption = function () {
	var loc = document.location;
	if (loc.hash == '#en') {
		// english
		setLocale('EN');
	} else if (loc.hash == '#jp') {
		// japaness
		setLocale('JP');
	} else {
		// korean
		setLocale('KO');
	}
	console.debug('set default locale as ' + getLocale());
};

const initLangSelector = function () {
	$('.lang-selector__js')
		.val(getLocale())
		.on('change', function (e) {
			let val = $(this).val();
			document.location = '/?lang=' + val;
		});
};

const loadMessageBundle = function (target) {
	if (_messageLoadMap[target]) {
		console.info('i18n module has already loaded request locale pack [' + target + ']');
		return;
	}
	import(`${target}`)
		.then(function (response) {
			$.each(response.default, function (k, v) {
				if (!_messageMap[k]) {
					_messageMap[k] = {};
				}
				$.each(v, function (k2, v2) {
					if (_messageMap[k][k2]) {
						console.warn('i18n module has detected same locale key [' + k + '.' + k2 + ']. old value replaced with new value.');
					}
					_messageMap[k][k2] = v2;
				});
			});
			_messageLoadMap[target] = true;

			console.debug(_messageMap);
		})
		.catch(function (error) {
			console.error(error);
		});
};

const localizeHtml = function (html, forcedLocale) {
	console.debug('localizeHtml -- ');

	var tmp = $('<div/>').html(html);
	tmp.find('[data-i18n]').each(function () {
		var ele = $(this);
		var msgId = ele.attr('data-i18n');
		var msg = getMessage(msgId, forcedLocale);

		if (ele.prop('nodeName').toLowerCase() == 'input') {
			ele.prop('placeholder', msg);
		} else {
			ele.html(msg);
		}
	});
	return tmp.html();
};

export { getLocale, setLocale, getMessage, localizeHtml, loadMessageBundle, detectLanguageOption, initLangSelector };
